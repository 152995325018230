<template>
  <div class="rtl">
    <v-card>
      <v-card-title>
        اکانت های مارکت
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="MarketAccounts" :search="search">
        <template v-slot:item.operations="{ item }">
          <v-row>

            <span @click="TradeDissAllow(item.id)" v-if="item.tradeAllowed === true " class="redButton">معاملات بسته شود </span>
            <span @click="TradeAllow(item.id)" v-if="item.tradeAllowed === false " class="greenButton">معاملات باز شود </span>
          </v-row>
        </template>
        <template v-slot:item.tradeAllowed="{ item }">
          <i :class="[item.tradeAllowed === false ? 'flaticon-check red-check' : 'flaticon-check green-check']"></i>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axiosApi from "@/axios";
import Vue from "vue";

export default {
name: "MarketAccounts",
  data(){
    return{
      MarketAccounts : [],
      headers: [
        {
          text: 'وضعیت ',
          align: 'start',
          sortable: false,
          value: 'tradeAllowed',
        },
        {text: 'آی دی', value: 'id'},
        {text: 'نام کاربری', value: 'userUsername'},
        {text: 'نوع مارکت', value: 'marketAccountType'},
        {text: 'عملیات', value: 'operations'},
      ],
      search : ''
    }
  },
  mounted() {
    this.getMarketAccounts();
  },
  methods:{
    getMarketAccounts()
    {
      axiosApi().post('api/Manage/v1/MarketAccount/GetList')
        .then(({data})=>{
          if (data['isSuccess'] === true)
            this.MarketAccounts = data.data
        })
    },
    TradeDissAllow(id)
    {
      axiosApi().post('api/Manage/v1/MarketAccount/TradeDisallow',{
        id : id
      })
        .then(({data})=>
        {
          if (data['isSuccess'] === true)
            this.getMarketAccounts()
          Vue.$toast.open({
            message: 'تغییر وضعیت مارکت با موفقیت انجام گردید ',
            type: 'success',
            position:'top-right'
          })
        })
    },
    TradeAllow(id)
    {
      axiosApi().post('api/Manage/v1/MarketAccount/TradeAllow',{
        id : id
      })
        .then(({data})=>
        {
          if (data['isSuccess'] === true)
            this.getMarketAccounts()
          Vue.$toast.open({
            message: 'تغییر وضعیت مارکت با موفقیت انجام گردید ',
            type: 'success',
            position:'top-right'
          })
        })
    },
  }
}
</script>

<style scoped>

</style>
